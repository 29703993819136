import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not found">
      <div className="nomal-page">
        <h1>Page not found</h1>
        <p>
          お探しのページは見つかりません。
          <br />
          We couldn’t find what you were looking for.
          <br />
          <br />
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/" className="underline">ホームに戻る</Link>.<br/>
          <Link to="/en" className="underline">Go home</Link>.<br/>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
